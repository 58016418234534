import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Container as TakeActionContainer } from 'partials/common/TakeAction/styles';
import { WrapperContainer } from 'partials/common/contentWrapper/styles';
export var Partners = styled.div.withConfig({
  displayName: "styles__Partners",
  componentId: "sc-rerd6o-0"
})(["display:flex;flex-direction:column;padding:120px 0 90px;", "{font-size:14px;padding:60px 0 45px;}& > svg{margin:0 50px;}"], down('md'));
export var PartnersLogo = styled.div.withConfig({
  displayName: "styles__PartnersLogo",
  componentId: "sc-rerd6o-1"
})(["display:flex;justify-content:center;align-items:center;& > svg{margin:0 50px;}"]);
export var HighlightQuote = styled.div.withConfig({
  displayName: "styles__HighlightQuote",
  componentId: "sc-rerd6o-2"
})(["display:flex;margin-bottom:50px;justify-content:flex-end;width:calc(1192px + ((100vw - 1192px) / 2));margin-left:calc(((100vw - 1192px) / 2) * -1);@media only screen and (max-width:1472px){width:calc(100% - ((100% - 1000px) / 2) + 40px);margin-left:-40px;}@media only screen and (max-width:1080px){width:calc(100% + 40px);margin-left:-40px;}", "{width:calc(100% + 20px);margin-left:-20px;margin-bottom:40px;}", "{padding-right:20px;width:calc(100%);}background-color:#b8ecb6;"], down('md'), down('xs'));
export var QuoteContent = styled.div.withConfig({
  displayName: "styles__QuoteContent",
  componentId: "sc-rerd6o-3"
})(["display:flex;width:100%;max-width:1000px;@media only screen and (max-width:1080px){width:calc(100% - 40px);}q{font-size:36px;}figcaption{display:flex;flex-direction:column;font-size:26px;margin-top:40px;}", "{width:calc(100% - 20px);q{font-size:26px;}figcaption{font-size:18px;line-height:120%;margin-top:20px;cite{display:block;}}}"], down('md'));
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-rerd6o-4"
})(["display:flex;flex-direction:column;width:100%;min-width:100%;main{display:flex;flex-direction:column;align-items:center;padding:10px 0 50px;", "{}", "{margin-top:40px;}blockquote{display:flex;flex-direction:column;margin:60px auto;width:100%;max-width:1000px;font-size:20px;font-weight:600;line-height:120%;", "{margin:24px 0 40px;max-width:100%;}", "{font-size:18px;}}", "{p{padding-left:0;}}", "{blockquote{max-width:100%;}}}"], WrapperContainer, TakeActionContainer, down('md'), down('xs'), Partners, HighlightQuote);
export var HeroContent = styled.div.withConfig({
  displayName: "styles__HeroContent",
  componentId: "sc-rerd6o-5"
})(["display:flex;width:100%;height:100%;", "{height:100%;justify-content:center;}h1{display:flex;align-items:center;font-size:80px;font-weight:600;line-height:110%;text-transform:uppercase;max-width:500px;color:#ffffff;", "{max-width:200px;font-size:60px;}}"], WrapperContainer, down('xs'));