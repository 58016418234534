import { useContext } from 'react';
import { EmailValidationContext } from '../contexts/EmailValidationContext';
export function useEmailValidation() {
  var context = useContext(EmailValidationContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}